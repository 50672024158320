.sign-in-container {
    display: flex;
    align-items: center;
    width: auto;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
}

.MuiAutocomplete-popupIndicator {
    display: none !important;
}

select {
    cursor: pointer !important;
}

.app-test::after {
    background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><text x='0' y='15' fill='blue' font-size='20'>qa env</text></svg>");
    content: "";
    opacity: 0.35;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.app-development::after {
    background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><text x='0' y='15' fill='green' font-size='20'>dev env</text></svg>");
    content: "";
    opacity: 0.35;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}