.MuiCalendarPicker-root .Mui-selected {
    color: #fff !important;
}

.chassis-selector .MuiFormControl-root {
    display: flex;
}

.calendar-container {
    position: relative;
    left: calc(25vw - 12px);
    justify-content: unset !important;
    margin-top: -16px;
}