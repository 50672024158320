.last-ok {
    grid-area: a;
}

.last-ok {
    grid-area: b;
}

.history {
    grid-area: c;
    min-width: 400px;
}

.news {
    grid-area: d;
}

.notifications  {
    grid-area: e;
}

.home-container {
    display: grid;
    grid-template-columns: minmax(20px, auto);
    grid-auto-rows: minmax(100px, auto);
    gap: 24px;
    grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            "e";
}

@media (min-width: 576px) {
    .home-container {
        grid-template-columns: minmax(20px, auto);
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .home-container {
        grid-template-columns: minmax(20px, auto) 1fr;
        grid-template-areas:
            "a b"
            "c d"
            "x e";
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .home-container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            "a b d"
            "c c e";
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1320px) {
    .home-container {
        grid-template-columns: 1fr 1fr  minmax(20px, auto) 1fr;
        grid-template-areas:
            "a b c d"
            "x x x e";
    }
}
