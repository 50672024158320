html {
  background-color: rgb(251, 251, 251) !important;
  font-family: "Segoe UI Regular",sans-serif;
}

body {
  background-color: rgb(251, 251, 251) !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiButtonBase-root {
  color: rgb(0, 120, 212) !important;
  /*text-decoration: underline !important;*/
  text-transform: none !important;
}

.MuiAccordionSummary-root.MuiAccordionSummary-root {
  text-decoration: none !important;
}

.MuiButtonBase-root.no-underline {
  text-decoration: none !important;
}

.MuiListItem-root {
  font-size: 14px !important;
}

.container-fluid {
  padding: 0 24px !important;
}

td .MuiButton-root,
.news .MuiButton-root,
.notifications .MuiButton-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: -6px !important;
}

.table-borderless.table-borderless td {
  padding: 4px;
  height: 36.5px;
}

.no-margin-bottom.no-margin-bottom {
  margin-bottom: 0 !important;
}

.MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal[class$="-MuiAutocomplete-popper"] {
  max-width: 100% !important;
}

.MuiTablePagination-toolbar > * {
  margin-bottom: 0;
}

a:visited {
  color: purple;
}

.MuiDataGrid-cell, .MuiDataGrid-row {
  max-height: none !important;
}
.MuiDataGrid-cell {
  word-break: break-all;
  white-space: normal;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgb(0, 120, 212);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(0, 120, 212) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.chassis-selector .MuiAutocomplete-popper {
  width: unset !important;
}