nav {
    background: rgb(0, 59, 103);
    color: #fff;
}

nav a {
    color: #fff !important;
    text-decoration: none;
}

.nav-button.nav-button {
    color: #fff !important;
    text-decoration: none;
    font-size: 16px;
    padding: 10px 16px;
    font-family: "Segoe UI Regular",sans-serif;
    line-height: 28px;
    display: inline-block;
}

nav a:hover, .nav-item:hover {
    color: #fff;
    background-color: #00093b;
}

[id^="nav-menu_"] .MuiMenu-list {
    background-color: #003b67!important;
}

[id^="nav-menu_"] .MuiMenuItem-gutters {
    padding: 0;
}

[id^="nav-menu_"] .MuiMenuItem-gutters a {
    font-size: 14px !important;
    line-height: 1.4 !important;
    padding: 8px 12px !important;
    text-decoration: none;
    color: #fff !important;
    padding: 5px 8px;
    display: flex;
    width: 100%;
}

[id^="nav-menu_"] .MuiMenuItem-gutters a:hover {
    background-color: #212121 !important; /* TODO rgb(0, 59, 103) */
    color: #fff !important;
}