.chassis-selector .MuiAutocomplete-popper {
    position: relative !important;
    transform: none !important;
}

.preview-cell.preview-cell {
    padding-top: 6px !important;
}

.visited.visited {
    color: purple !important;
}

.order-details-container {
    display: flex;
    max-width: 660px;
    flex-direction: column;
}