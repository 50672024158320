header {
    background-color: #212121 !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0) !important;
    color: #fff !important;
}

.secondary-nav a {
    color: #fff;
    padding: 12px;
    font-size: 12px;
    text-transform: none;
    white-space: nowrap;
}

.role-row, .user-row {
    font-size: 12px;
}

.user-row {
    padding: 14px 0 12px 12px;
    margin-left: 12px;
}

.user {
    text-align: right;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.user-icon {
    height: 25px;
    margin: 6px 0 0 15px;
}

.user-icon svg {
    width: 100%;
    height: 100%;
}