.emails-accordion .MuiAccordionDetails-root {
    padding: 0 !important;
}

.emails-wrapper {
    display: block;
}

.emails-table td .MuiButton-root {
    margin-top: 0 !important;
}

.add-email-address .MuiFormHelperText-root {
    position: absolute;
    top: 60px;
    width: 300px;
}

.emails-grid {
    display: grid;
    grid-template-columns: 1fr
}

@media (min-width: 768px) {
    .emails-grid {
        display: grid;
        grid-template-columns: 1fr 1fr
    }
}
