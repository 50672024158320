.news {
    background-color: rgb(244, 244, 244);
}

.news .card-text,
.news .card-subtitle {
    font-size: 14px;
}

.view-all {
    display: flex;
    flex-direction: row-reverse;
}

.newsTable {
    width: 100%;
}

.newsTable, .news-card {
    white-space: pre-line;
}

.newsTable tbody {
}

.newsTable tr {
    border-bottom: 1px solid rgba(33, 37, 41, 0.25);
}

.newsTable td {
    padding: 10px 0 0 0;
}

.newsTable tr:last-child {
    border-bottom: none;
}

.show-more-link {
    color: rgb(0, 120, 212);
}

.newsTable {
    white-space: pre-line;
}

.newsTable tr {
    border-bottom: 1px solid rgba(33, 37, 41, 0.25);
}

.newsTable td {
    padding: 10px 0 0 0;
}

.newsTable tr:last-child {
    border-bottom: none;
}

.show-more-link {
    color: rgb(0, 120, 212);
}

.close-news-modal:hover {
    background-color: transparent !important;
}