.copy-password-icon {
    margin-right: 3px;
}

.table-master-id-password {
    border-bottom: 3px solid #dedede !important;
    width: fit-content;
}

.table-master-id-password:last-child {
    border-bottom: none !important;
}